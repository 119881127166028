const PARAMS = {
  MAC: 'DEVICE_MAC',
  NAME: 'DEVICE_NAME',
  MANUFACTURER: 'MANUFACTURER',
};

const VARIABLES = {
  PRESENCE_STATUS: 'LANMANAGER_PRESENCE_STATUS',
  PRESENCE_FREQUENCY: 'LANMANAGER_PRESENCE_FREQUENCY',
  IP_MASKS: 'LANMANAGER_IP_MASKS',
};

const PRESENCE_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

const TIMERS = {
  PRESENCE: 120000,
  SCAN: 30000,
};

const SCAN_OPTIONS = {
  IP_FAMILY: ['IPv4'],
};

const UTILS = {
  IP_MASK_OPTION_SEPARATOR: '|',
  IP_MASK_VALUE_SEPARATOR: ':',
};

module.exports = { PARAMS, VARIABLES, PRESENCE_STATUS, TIMERS, SCAN_OPTIONS, UTILS };
